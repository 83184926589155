<template>
  <div
    class="@container bg-neutral-light-2 text-on-neutral dark:bg-neutral-dark-2 flex h-full w-full flex-col items-center justify-center shadow-xl"
  >
    <div class="@sm:space-y-10 items-center justify-center space-y-1">
      <p
        v-if="canBeActivated"
        class="text-center"
        v-text="t('message.warning', { vendor: vendor?.name })"
      />
      <p
        v-else
        class="text-center"
        v-text="t('message.blocked', { domain: foreignDomains.join(',') })"
      />

      <div
        v-if="canBeActivated"
        class="@sm:flex-row @sm:space-x-4 @sm:space-y-0 flex flex-col items-center justify-center space-y-2"
      >
        <KButton
          :theme="BUTTON_THEME.SECONDARY"
          :size="BUTTON_SIZE.SM"
          :text="t('btn.settings')"
          @click="toggleModal({ initial: false, settings: true })"
        />
        <KButton
          v-if="vendor && consentMode === CONSENT_MANAGER_MODE.VENDOR_SELECTION"
          :theme="BUTTON_THEME.PRIMARY"
          :size="BUTTON_SIZE.SM"
          :text="t('btn.allow')"
          @click="setConsentForVendor(vendor.id, true)"
        />
        <KButton
          v-if="
            purpose && consentMode === CONSENT_MANAGER_MODE.PURPOSE_SELECTION
          "
          :theme="BUTTON_THEME.PRIMARY"
          :size="BUTTON_SIZE.SM"
          :text="t('btn.allowPurpose', { purpose: purpose.title })"
          @click="setConsentForPurpose(purpose.category, true)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { CmsConsentPurpose, CmsConsentVendor } from '#gql/default'

interface Props {
  canBeActivated: boolean
  foreignDomains: string[]
}

const { foreignDomains, canBeActivated } = defineProps<Props>()

if (!canBeActivated) {
  const { currentUrl } = useCurrentUrl()
  await useAsyncGql({
    operation: 'LogMissingConsent',
    variables: { pageUrl: currentUrl, resourceUrl: foreignDomains[0] },
  })
}

const { t } = useI18n()
const {
  getVendorForDomain,
  getVendorPurpose,
  setConsentForVendor,
  setConsentForPurpose,
  toggleModal,
  consentMode,
} = useConsentManager()

const vendor = computed(
  () => getVendorForDomain({ domain: foreignDomains[0] }) as CmsConsentVendor,
)
const purpose = computed(
  () => getVendorPurpose(vendor.value?.id as string) as CmsConsentPurpose,
)

defineOptions({
  name: 'ConsentManagerWarning',
})
</script>

<i18n>
de:
  message:
    warning: "Diese Seite möchte Daten von {vendor} übertragen"
    blocked: "Die Übertragung von Daten von {domain} wurde unterbunden"
  btn:
    allow: "Zulassen"
    allowPurpose: "{purpose} zulassen"
    settings: "Einstellungen"
es:
  message:
    warning: "Esta página quiere transferir datos de {vendor}"
    blocked: "Se ha bloqueado la transferencia de datos de {domain}"
  btn:
    allow: "Permitir"
    allowPurpose: "Permitir {purpose}"
    settings: "Configuración"
en:
  message:
    warning: "This page wants to transfer data from {vendor}"
    blocked: "The transfer of data from {domain} has been blocked"
  btn:
    allow: "allow"
    settings: "Settings"
</i18n>
